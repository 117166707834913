<template>
<div class="add-my-coursefeeds-tab">
  <form-wizard
    color="rgba(var(--vs-primary), 1)"
    title=""
    :subtitle="null"
    finishButtonText="Submit"
    @on-complete="formSubmitted"
    @on-error="handleErrorMessage"
  >
    <tab-content title="Step 1" class="mb-5" :beforeChange="validateStepOne">
      <!-- tab 1 content -->
      <div class="vx-row">
        <div class="vx-col w-full">
          <h4 class="mb-2">Let's get started!</h4>
          <p>Add your group details below.</p>
        </div>
        <div class="vx-col w-full mt-4">
          <label class="">Name</label>
          <vs-input v-model="title" class="w-full" />
        </div>

        <div class="vx-col w-full md:w-1/2 mt-4">
          <label class="">Category</label>
          <v-select
            v-model="category"
            label="text"
            :options="categories"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          />
        </div>
        <div class="vx-col w-full md:w-1/2 mt-4">
          <label class="">Add Topics</label>
          <v-select
            v-model="topics"
            label="text"
            taggable
            multiple
            :options="topicsList"
            :closeOnSelect="false"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            @option:created="handleTopicOptionCreate"
          />
        </div>
        <div class="vx-col w-full mt-5">
          <vs-textarea
            v-model="headline"
            label="Headline"
            class="mb-0"
            rows="1"
          />
        </div>
        <!-- <div class="vx-col w-full mt-5">
          <vs-textarea
            v-model="description"
            label="Detailed description"
            class="mb-0"
            rows="5"
          />
        </div> -->
        <div class="vx-col w-full mt-4">
          <label class="">Description</label>
           <quill-editor v-model="description"></quill-editor>
        </div>
      </div>
    </tab-content>

    <!-- tab 2 content -->
    <tab-content title="Step 2" class="mb-5" :beforeChange="validateStepTwo">
      <div class="vx-row">
        <div class="vx-col w-full">
          <h4 class="mb-2">Select group type </h4>
          <p class="mb-2"><span class="font-weight-bold"> - Public Group:</span> Anyone can join your group for free.</p>
           <p><span class="font-weight-bold">- Paid/Private Group:</span> You can monetise your groups by charging joining fees.</p>
        </div>
        <div class="vx-col w-full mt-5">
          <label class="">Select your group type</label>
          <v-select
            v-model="courseFeedType"
            :options="types"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          />
        </div>
        <template v-if="courseFeedType === 'Private'">
          <div class="vx-col w-full mt-5">
          <h5 class="mb-0">Pricing:</h5>
          </div>
          <div class="vx-col w-full md:w-1/2 mt-4" >
            <label class="">Actual Price (Incl. Tax)</label>
            <vx-input-group
              class=""
              prependClasses="border border-solid border-grey border-r-0"
            >
              <template slot="prepend">
                <div class="prepend-text">
                  <span>$</span>
                </div>
              </template>
              <vs-input v-model="price" type="number" />
            </vx-input-group>
          </div>

          <div class="vx-col w-full md:w-1/2 mt-4">
            <label class="">Discount Price (Incl. Tax)</label>
            <vx-input-group
              class=""
              prependClasses="border border-solid border-grey border-r-0"
            >
              <template slot="prepend">
                <div class="prepend-text">
                  <span>$</span>
                </div>
              </template>
              <vs-input v-model="discountPrice" type="number" />
            </vx-input-group>
          </div>
           <div class="vx-col w-full">
      <div class="tip-note">
        <div class="flex mb-3">
          <feather-icon icon="SmileIcon" svgClasses="h-5 w-5" class="mr-2" />
          <h5 class="mb-0">Hints</h5>
        </div>
        <span
          >First, you need to set up your payout account in Settings page to create paid groups.</Br></span
        >
        <span
          >Our platform fee is 5% of the joining fees. (No fees upto 100 paid members)</span
        >
      </div>
    </div>
        </template>
      </div>
    </tab-content>

    <!-- tab 3 content -->
    <tab-content title="Step 3" class="mb-5" :beforeChange="validateStepThree">
      <div class="vx-row">
        <div class="vx-col w-full">
          <h4 class="mb-2">Upload Image</h4>
        </div>
        <div class="vx-col w-full mt-5">
          <label class="">Upload your group image</label>
          <!-- <input ref="imageInput" type="file" @change="handleImageChange"/> -->
          <!-- <b-form-file ref="imageInput" v-model="file1" class="" plain @change="handleImageChange"></b-form-file> -->
          <b-form-file
      v-model="file"
      placeholder="Choose a file or drop it here..."
      drop-placeholder="Drop file here..."
      size="lg"
      ref="imageInput"
     @change="handleImageChange"
    ></b-form-file>
          <img :src="this.previewImage" class="w-full md:w-1/2 mt-3"/>

          <!-- <div class="mt-3">
          Upload video: {{ video ? video.name : "" }}
          <b-form-file
      v-model="file"
      placeholder="Choose a file or drop it here..."
      drop-placeholder="Drop file here..."
      size="lg"
      ref="videoInput"
     @change="handleVideoChange"
    ></b-form-file>
          </div> -->

        </div>
        <div class="vx-col w-full">
    <!--  <div class="tip-note">
        <div class="flex mb-3">
          <feather-icon icon="SmileIcon" svgClasses="h-5 w-5" class="mr-2" />
          <h5 class="mb-0">Note:</h5>
        </div>
        <span
          >Please note, your listing will be send for reviewing process and you will get an email when its approved and listed.</span
        > 
      </div>-->
    </div>
      </div>
    </tab-content>
  </form-wizard>
</div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";
import { API, graphqlOperation } from "@aws-amplify/api";
// import { listCategorys, topicsByCategory} from '@/graphql/queries';
import { createCourseFeed, createTopic } from "@/graphql/mutations";
import { Storage } from '@aws-amplify/storage';

import { createErrorObject, createSuccessObject } from '@/utils'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'AddCoursefeedsTab',
  props: {
    payout_account_activated: false,
  },
  data() {
    return {
      title: '',
      headline: '',
      category: '',
      categories: [],
      topics: [],
      topicsList: [],
      description: '',
      courseFeedType: '',
      types: ['Public', 'Private'],
      price: '',
      discountPrice: '',
      image: '',
      previewImage: '',
      video: '',
      file1:'null',
      file2:'null',
    };
  },
  watch: {
    category (newVal) {
      this.topics = []
      this.fetchTopic(newVal)
    }
  },
  // created () {
  //   this.fetchCategory()
  // },
  methods: {
    async formSubmitted() {
      try {
        this.$vs.loading()
        let imageObj = ''
        let videoObj = ''
        if (this.image) {
          imageObj = await Storage.put(this.image.name, this.image)
        }
  
        if (this.video) {
          videoObj = await Storage.put(this.video.name, this.video)
        }
        const createRes = await API.graphql(graphqlOperation(createCourseFeed, {
          input: {
            title: this.title,
            user: this.$store.state.auth.userData.name,
            category: this.category.text,
            topics: this.topics.map(item => item.text),
            headline: this.headline,
            description: this.description,
            type: this.courseFeedType,
            price: this.price !== '' ? this.price : 0,
            discountPrice: this.discountPrice !== '' ? this.discountPrice : 0,
            createdAt: new Date(),
            image: (imageObj !== '') ? imageObj.key : '',
            video: (videoObj !== '') ? videoObj.key : '',
            status: 'Active',
          }
        }))

        if (createRes.data.createCourseFeed) {
          this.$vs.notify(createSuccessObject('Success', 'Coursefeed created'))
          this.$emit('coursefeedAdded', createRes.data.createCourseFeed)
        }
        this.$vs.loading.close()
      } catch (err) {
        this.$vs.notify(createErrorObject('Error', 'Unable to create Coursefeed'))
        console.log(err)
      }
      formData.append("description", this.description);
    },
    handleErrorMessage (err) {
      if (err) {
        this.$vs.notify(createErrorObject('Error', err))
      }
    },
    validateStepOne () {
      return new Promise((resolve, reject) => {
        if (this.title === '') {
          reject('Title cannot be empty')
        }
  
        if (this.headline === '') {
          reject('Headline cannot be empty')
        }
  
        if (this.description === '') {
          reject('Description cannot be empty')
        }

        if (this.category === '') {
          reject('Category cannot be empty')
        }

        if (this.topics.length === 0) {
          reject('Topics cannot be empty')
        }

        resolve(true)
      })
      
    },
    validateStepTwo () {
      return new Promise((resolve, reject) => {
        if(this.courseFeedType === ''){
            reject('Please select public or private community you wanted to create')
        }
        if(this.$props.payout_account_activated === false  && this.courseFeedType === 'Private'){
            reject('Please setup your payout account under settings page.')
        }
        if (this.type === '') {
          reject('Please select group type')
        }
        if (this.price === '' && this.courseFeedType === 'Private') {
          reject('Please add actual price')
        }
        if (this.discountPrice === '' && this.courseFeedType === 'Private') {
          reject('Please add discount price')
        }

        resolve(true)
      })
    },
    validateStepThree () {
      return new Promise((resolve, reject) => {
        if (this.image === '') {
          reject('Please upload picture for this coursefeed')
        }
        resolve(true)
      })
    },
    handleImageChange (e) {
      if (e.target.files[0]) {
        this.image = e.target.files[0]
        this.previewImage = URL.createObjectURL(this.image)
      }
    },
    handleVideoChange (e) {
      if (e.target.files[0]) {
        this.video = e.target.files[0]
      }
    },
    async handleTopicOptionCreate (newOption) {
      try {
        let name = newOption.text ? newOption.text : newOption
        const res = await API.graphql(graphqlOperation(createTopic, {
          input: {
            category: this.category.val,
            name
          }
        }))

        this.topicsList.push({ text: name, val: res.data.createTopic.id })
      } catch (err) {
        console.log('handleTopicOptionCreate', err)
      }
    },
    // async fetchCategory () {
    //   try {
    //     const res = await API.graphql(graphqlOperation(listCategorys))
    //     this.categories = res.data.listCategorys.items.map(item => { return { text: item.name, val: item.id }})
    //     // console.log('categories', this.categories)
    //   } catch (err) {
    //     console.log('fetchCategory', err)
    //   }
    // },
    async fetchTopic (category) {
      try {
        const res = await API.graphql(graphqlOperation(topicsByCategory, {
          category: category.val
        }))
        this.topicsList = res.data.topicsByCategory.items.map(item => {
          return { text: item.name, val: item.id }
        })
      } catch (err) {
        console.log('fetchTopic', err)
      }
    }
  },
  components: {
    FormWizard,
    TabContent,
    "v-select": vSelect,
    quillEditor,
  },
};
</script>

<style lang="scss">
@media only screen and (max-width: 600px) {
  .add-my-coursefeeds-tab .wizard-nav, .add-my-coursefeeds-tab .wizard-progress-with-circle{
    display: none;

  }
  .add-my-coursefeeds-tab .vue-form-wizard .wizard-tab-content{
    padding-top: 0 !important;

  }
}
</style>
