<template>
  <div class="">
    <div class="">
      <div class="vx-row">
        <div class="vx-col w-full mt-5 mb-base">
          <div class="sessions-tabs">
            <b-nav pills class="scrollmenu">
              <b-nav-item :active="isAllCatSelected" @click="filterCoursefeed('all')">All</b-nav-item>
              <b-nav-item
                v-for="(cat, index) in categories"
                :key="index"
                @click="filterCoursefeed(cat.name, index)"
                :active="cat.isSelected"
              >
                {{ cat.name }}
              </b-nav-item>
            
            </b-nav>
          </div>
        </div>
      </div>

 

      <div class="vx-row">
        <div
          v-for="(cf, index) in filtered"
          :key="index"
          class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/4 mb-base coursefeeds-list"
        >
          <vx-card>
            <div @click="courseDetails(cf)" >
              <img
                :src="cf.imageURL"
                alt="content-img"
                class="responsive rounded-lg coursefeeds-image"
              />
              <div class="my-6">
                <h4 class="mb-2 truncate">{{ cf.title }}</h4>
                <span class="text-grey">{{ cf.userRef.displayName }}</span>
                <div v-html="cf.headline" class="text-truncate" >
                  <!-- {{ cf.description }} -->
                  {{ cf.description }}
                </div>
              </div>
            </div>
            <vs-divider></vs-divider>
            <div class="flex justify-between flex-wrap items-center">
              <span>
                <p class="text-xl">
                  <div class="" v-if="cf.type === 'Private'">
                  <span class="font-semibold text-success" >
                        {{ cf.discountPrice === 0 ? '' : '$' +cf.discountPrice}}</span
                      >                      
                      <span
                        class="text-primary ml-1"
                      :class="
                          cf.discountPrice 
                            ? 'line-through'
                            : '' 
                        "
                        >{{ cf.price === 0 ? '' : '$'  + cf.price }}</span
                      >
                </div>
                <div class="" v-if="cf.type === 'Public'">
                  <span class="font-semibold text-primary h5">Free</span>
                </div>
                </p>
                <!-- <div class="d-flex">
                  <div class="d-flex text-warning ml-1">
                    <span class="mr-1">4.7</span>
                    <feather-icon icon="StarIcon" svgClasses="h-4 w-4" />
                  </div>
                  <span class="ml-2 text-muted">(48 reviews)</span>
                </div> -->
              </span>
              <span>
                <vs-button
                  v-if="cf.isCourseFeedSubscribedByUser === false"
                  color="primary"
                  type="filled"
                  @click="joinCourseFeed(cf)"
                  class="w-full"
                  size="small"
                  >Join Now</vs-button
                >
                <div v-else class="flex items-center">
                <span  >
                  <span class="font-normal text-success h6 mb-0">Joined</span>                 
                </span>

                <div class="">   
                   <vs-button
                  v-if="cf.isCourseFeedSubscribedByUser === true"
                  color="success"
                  type="filled"
                  @click="visitCourseFeed(cf)"
                  class="ml-2"
                  size="small"
                  >Visit</vs-button
                >
                </div>
                </div>
              </span>
              
            </div>
            <!-- <div class="">   
                   <vs-button
                  v-if="cf.isCourseFeedSubscribedByUser === true"
                  color="success"
                  type="filled"
                  @click="visitCourseFeed(cf)"
                  class="mt-2"
                  size="small"
                  >Visit</vs-button
                >
            </div> -->
              <p style="padding-top:0px;" v-if="cf.isCourseFeedSubscribedByUser === false" ></p>
          </vx-card>
        </div>
      </div>
    </div>
 <!-- v-if="isPartner" -->
    <a
      href="#"
     
      @click="openAddCourseFeed()"
      class="coursefeeds-add-btn"
    >
      <i class="fa fa-plus"></i>
    </a>

    <!-- Add Coursefeed poppup -->
    <div class="">
      <vs-popup
        class="holamundo"
        title="Create New Group "
        :active.sync="activeAddCoursefeedTab"
      >
        <div class="">
          <add-coursefeeds-tab :payout_account_activated="payout_account_activated" @coursefeedAdded="handleCoursefeedAdded" />
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import AddCoursefeedsTab from "./AddCoursefeedsTab.vue";
import { API, graphqlOperation } from "@aws-amplify/api";
import { Storage } from "@aws-amplify/storage";
// import { listCourseFeeds, listCategorys } from "@/graphql/queries";
import { createSuccessObject, createErrorObject } from '@/utils'
import { getCourseFeed, courseFeedSubscriptionByUser } from '@/graphql/queries'
import { createCourseFeedSubscriber } from '@/graphql/mutations'

export default {
  name: "CourseFeeds",
  data() {
    return {
      activeAddCoursefeedTab: false,
      courseFeed:'',
      coursefeeds: [],
      categories: [],
      filtered: [],
      isAllCatSelected: true,
      payout_account_activated : false,
    };
  },
  components: {
    AddCoursefeedsTab,
  },
  computed: {
    isPartner () {
      return this.$store.state.auth.userData.isPartner
    },
  },
  methods: {
    async openAddCourseFeed() {
      this.activeAddCoursefeedTab = true;
      let stripeConnectedAccountKey = this.$store.state.auth.userData.stripeConnectedAccountKey;
      if(stripeConnectedAccountKey != undefined && stripeConnectedAccountKey != null){
          API.post('cpRestAPI', '/stripeaccount/accountlinks/retrieve', {
                headers: {
                  'Content-Type': 'application/json'
                },
                body: {
                  stripeConnectedAccountKey:this.$store.state.auth.userData.stripeConnectedAccountKey
              }}).then(res => {
                if(res.success === true){  
                  let details_submitted = res.response.details_submitted;
                  if(details_submitted === true){                    
                    // this.activeAddCoursefeedTab = true;          
                    this.payout_account_activated = true;
                  }else{             
                    // this.activeAddCoursefeedTab = false;          
                    this.payout_account_activated = false;
                    // this.$vs.notify(createErrorObject('Error', 'Please setup your payout account under settings page to create community'));
                  }
                }
              })
              .catch(err => {
                  console.log(err);
              })
      }else{
        // this.activeAddCoursefeedTab = false;          
        this.payout_account_activated = false;
        // this.$vs.notify(createErrorObject('Error', 'Please setup your payout account under settings page to create community'));
      }
    },
    getHtmlValue(val) {
      var div = document.createElement("div");
      div.innerHTML = val;
      // console.log(div.innerHTML);
      return div.innerHTML.replace(/<\/?[^>]+(>|$)/g, "");
    },
    async joinCourseFeed (cf) {
    //   console.log(cf.id); 
      this.courseFeed = cf;
			try {
          const findRes = await API.graphql(graphqlOperation(courseFeedSubscriptionByUser, {
            user: this.$store.state.auth.userData.name,
            courseFeed: {
              eq: this.courseFeed.id
            }
          }))
          if (findRes.data.courseFeedSubscriptionByUser.items.length === 0) {            
            if (this.courseFeed.type === 'Private') {
              this.booknowCourse = true
            } else {
                const res = await API.graphql(graphqlOperation(createCourseFeedSubscriber, {
                  input: {
                    courseFeed: this.courseFeed.id,
                    user: this.$store.state.auth.userData.name
                  }
                }))
                this.sendNewUserJoinedMailToEducator();
                this.sendNewUserJoinedMailToUser();
                this.$vs.notify(createSuccessObject('Success', 'Great work, You have joined this group now!'))
                const findRes = await API.graphql(graphqlOperation(courseFeedSubscriptionByUser, {
                  user: this.$store.state.auth.userData.name,
                  courseFeed: {
                    eq: this.courseFeed.id
                  }
                }))    
                console.log(findRes.data.courseFeedSubscriptionByUser.items[0]);                          
                this.$store.dispatch('channel/switchChannel', {
                    channel:findRes.data.courseFeedSubscriptionByUser.items[0] ,
                    type : 'COURSE',
                    router: this.$router,
                    loader: this.$vs.loading        
                })  
                // this.$router.push(`/course-details/${cf.id}`);
            }
          } else {
            this.$vs.notify(createErrorObject('Error', 'You have already joined this group'))
          }
				
			} catch (err) {
				console.log('joinCourseFeed', err)
			}
		},
    async sendNewUserJoinedMailToEducator() {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();            
        urlencoded.append("template_code", 'new_user_joined');
        urlencoded.append("to", this.courseFeed.userRef.email);
        urlencoded.append("bcc", 'support@coursepal.app');
        urlencoded.append("username", this.$store.state.auth.userData.displayName);
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: urlencoded,
          redirect: "follow"
        };   
        const rawResponse = await fetch(
          // process.env.VUE_APP_BASE_URL + "/api/sendTestMail",
          `${process.env.VUE_APP_BASE_URL}/api/sendAppMail`,
          // "https://api.coursepal.app/api/sendAppMail",
          // "http://localhost:7000/api/sendAppMail",
          requestOptions
        );  
        const response = await rawResponse.json();
        if (response.status == 1) {
        }                  
    },
    async sendNewUserJoinedMailToUser() {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();            
        urlencoded.append("template_code", 'welcome_coursefeed');
        urlencoded.append("to", this.$store.state.auth.userData.email);
        urlencoded.append("bcc", 'support@coursepal.app');
        urlencoded.append("username", this.$store.state.auth.userData.displayName);
        urlencoded.append("coursefeed_title", this.courseFeed.title);
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: urlencoded,
          redirect: "follow"
        };   
        const rawResponse = await fetch(
          // process.env.VUE_APP_BASE_URL + "/api/sendTestMail",
          `${process.env.VUE_APP_BASE_URL}/api/sendAppMail`,
          // "https://api.coursepal.app/api/sendAppMail",
          // "http://localhost:7000/api/sendAppMail",
          requestOptions
        );  
        const response = await rawResponse.json();
        if (response.status == 1) {
        }                  
    },
    courseDetails(cf) {
      this.$router.push(`/course-details/${cf.id}`);
    },
    async visitCourseFeed(cf) {
      this.courseFeed = cf;
			try {
          const findRes = await API.graphql(graphqlOperation(courseFeedSubscriptionByUser, {
            user: this.$store.state.auth.userData.name,
            courseFeed: {
              eq: this.courseFeed.id
            }
          }))
          if (findRes.data.courseFeedSubscriptionByUser.items.length !== 0) {            
             this.$store.dispatch('channel/switchChannel', {
                channel:findRes.data.courseFeedSubscriptionByUser.items[0] ,
                type : 'COURSE',
                router: this.$router,
                loader: this.$vs.loading        
              })  
          }
				
			} catch (err) {
				console.log('visitCourseFeed', err)
			} 
    },
    filterCoursefeed(cat, index) {
      this.categories.map(item => item.isSelected = false)
      this.isAllCatSelected = false
      if (cat === "all") {
        this.isAllCatSelected = true
        this.filtered = this.coursefeeds;
      } else {
        this.categories[index].isSelected = true
        this.filtered = this.coursefeeds.filter((item) => item.category === cat);
      }
    },
    async handleCoursefeedAdded(coursefeed) {
      this.activeAddCoursefeedTab = false;
      if (coursefeed.image) {
        const url = await Storage.get(coursefeed.image);
        coursefeed.imageURL = url;
      }
      this.coursefeeds.push(coursefeed);
      this.filtered = this.coursefeeds;
    },
    // async fetchCategories() {
    //   try {
    //     const res = await API.graphql(graphqlOperation(listCategorys));
    //     if (
    //       res.data.listCategorys.items &&
    //       res.data.listCategorys.items.length !== 0
    //     ) {
    //       for (const item of res.data.listCategorys.items) {
    //         item.isSelected = false;
    //       }
    //       this.categories = res.data.listCategorys.items;
    //     }
    //   } catch (err) {
    //     console.log(err);
    //   }
    // },
    async fetchData() {

      try {
        this.$vs.loading()
        const res = await API.graphql(graphqlOperation(listCourseFeeds,{
          filter: {status: {eq: "Active"}}
        }));
        for (const cf of res.data.listCourseFeeds.items) {
          if (cf.image) {
            const url = await Storage.get(cf.image);
            cf.imageURL = url;
          }
        }
        let coursefeeds = [];
        for (let index = 0; index < res.data.listCourseFeeds.items.length; index++) {
          let element = res.data.listCourseFeeds.items[index];
          const findRes = await API.graphql(graphqlOperation(courseFeedSubscriptionByUser, {
            user: this.$store.state.auth.userData.name,
            courseFeed: {
              eq: element.id
            }
          }))
          if (findRes.data.courseFeedSubscriptionByUser.items.length !== 0) {   
            element.isCourseFeedSubscribedByUser = true;
          }else{
            element.isCourseFeedSubscribedByUser = false;
          }
          coursefeeds.push(element);
        }
        this.coursefeeds = coursefeeds;
        this.filtered = coursefeeds;
        this.$vs.loading.close()
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.$store.commit("TOGGLE_REDUCE_BUTTON", true);
  },
  created() {
    // this.fetchCategories();
    this.fetchData();
  },
};
</script>

<style scoped>
.nav-link.active {
  color: white !important;
}
</style>